import api from '@helpers/api'
import qs from 'qs'

class UserService {
  constructor() {
    this.path = 'user'
  }

  async getID({ id }) {
    const response = await api.get(`/${this.path}/${id}`)
    return response.data
  }

  async getAll({ page, q }) {
    const response = await api.get(`/${this.path}`, {
      params: {
        page,
        q,
      },
    })
    return response.data
  }

  async getSelectOptions() {
    const response = await api.get(`/${this.path}/select/options`)
    return response.data
  }

  async create(data) {
    const response = await api.post(`/${this.path}`, data)
    return response.data
  }

  async update(data) {
    const { id } = data
    const response = await api.put(`/${this.path}/${id}`, data)
    return response.data
  }

  async updateStatus(data) {
    const { id } = data
    const response = await api.put(`/${this.path}/${id}/status`, data)
    return response.data
  }

  async delete({ id }) {
    const response = await api.delete(`/${this.path}/${id}`)
    return response.data
  }

  async report(filters) {
    const response = await api.get(`/${this.path}/report/excel`, {
      params: filters,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    return response.data
  }

  async import(data) {
    const response = await api.post(`/${this.path}/import/excel`, data)
    return response.data
  }

  async sendEmailWelcome({ id }) {
    const response = await api.post(`/${this.path}/${id}/email/welcome`)
    return response.data
  }
}

export default new UserService()
