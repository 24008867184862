<template>
  <nav>
    <ul class="pagination justify-content-center mb-0">
      <template v-if="pagination.current_page !== 1">
        <li class="page-item">
          <a
            class="page-link"
            href="javascript:void(0)"
            @click.prevent="changePage(1)"
            :disabled="pagination.current_page <= 1"
          >
            <b aria-hidden="true"> << </b>
          </a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            href="javascript:void(0)"
            @click.prevent="changePage(pagination.current_page - 1)"
            :disabled="pagination.current_page <= 1"
          >
            <b aria-hidden="true"> < </b>
          </a>
        </li>
      </template>
      <li
        class="page-item"
        :key="key"
        :class="isCurrentPage(page) ? 'active' : ''"
        v-for="(page, key) in pages"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          @click.prevent="changePage(page)"
        >
          <b>{{ page }}</b>
        </a>
      </li>
      <template v-if="pagination.current_page !== pagination.last_page">
        <li class="page-item">
          <a
            class="page-link"
            href="javascript:void(0)"
            @click.prevent="changePage(pagination.current_page + 1)"
            :disabled="pagination.current_page >= pagination.last_page"
          >
            <b aria-hidden="true"> > </b>
          </a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            href="javascript:void(0)"
            @click.prevent="changePage(pagination.last_page)"
            :disabled="pagination.current_page >= pagination.last_page"
          >
            <b aria-hidden="true"> >> </b>
          </a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['pagination', 'offset'],
  methods: {
    isCurrentPage(page) {
      return this.pagination_mutation.current_page === page
    },
    changePage(page) {
      if (page > this.pagination_mutation.last_page) {
        page = this.pagination_mutation.last_page
      }
      this.pagination_mutation.current_page = page
      this.$emit('paginate')
    },
  },
  model: {
    prop: 'pagination',
    event: 'change',
  },
  computed: {
    pagination_mutation: {
      get: function () {
        return this.pagination
      },
      set: function (value) {
        this.$emit('change', value)
      },
    },
    pages() {
      let pages = []
      let from =
        this.pagination_mutation.current_page - Math.floor(this.offset / 2)
      if (from < 1) from = 1
      let to = from + this.offset - 1
      if (to > this.pagination_mutation.last_page)
        to = this.pagination_mutation.last_page
      while (from <= to) {
        pages.push(from)
        from++
      }
      return pages
    },
  },
}
</script>
