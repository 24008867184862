<template>
  <div id="app">
    <loading />
    <router-view />
  </div>
</template>

<script>
import appConfig from '@src/app.config'

export default {
  page: {
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
}
</script>

<style lang="scss">
@import '@assets/scss/hope-ui.scss';
@import '@assets/scss/custom.scss';
@import '@assets/scss/dark.scss';
@import '@assets/scss/rtl.scss';
@import '~sweetalert2/src/sweetalert2.scss';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
</style>
