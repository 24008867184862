<template>
  <transition appear>
    <loading-layout />
  </transition>
</template>

<script>
import LoadingLayout from '@layouts/LoadingLayout.vue'

export default {
  name: 'loading',
  components: {
    LoadingLayout,
  },
}
</script>
