<template>
  <a href="javascript:void(0)" class="navbar-brand">
    <img class="brand-navbar" src="@assets/images/logo.png" />
  </a>
</template>

<script>
export default {
  name: 'LogoNav',
  components: {},
}
</script>
