import jwt_decode from 'jwt-decode';

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.refresh_token;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.access_token;
  }

  getLocalAccessTokenDecode() {
    const user = JSON.parse(localStorage.getItem('user'));
    const access_tpken = user?.access_token;
    return jwt_decode(access_tpken);
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.access_token = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  setUser(user) {
    let userStringify = JSON.stringify(user);
    localStorage.setItem('user', userStringify);
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}

export default new TokenService();
