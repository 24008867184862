<template>
  <div
    class="
      sidebar-header
      d-flex
      align-items-center
      justify-content-start
      bg-custom3
    "
  >
    <logo-nav />
    <div
      class="sidebar-toggle d-block d-sm-none"
      data-toggle="sidebar"
      data-active="true"
    >
      <i class="icon">
        <span class="fas fa fa-arrow-left" />
      </i>
    </div>
  </div>
</template>

<script>
import LogoNav from '@components/app/sub_component/LogoNav.vue'

export default {
  inheritAttrs: false,
  components: {
    LogoNav,
  },
  async mounted() {
    this.initSideBarToggle()
  },
  methods: {
    sidebarToggle(elem) {
      elem.addEventListener('click', (e) => {
        const sidebar = document.querySelector('.sidebar')
        if (sidebar.classList.contains('sidebar-mini')) {
          sidebar.classList.remove('sidebar-mini')
        } else {
          sidebar.classList.add('sidebar-mini')
        }
      })
    },
    initSideBarToggle() {
      const sidebarToggleBtn = document.querySelectorAll(
        '[data-toggle="sidebar"]'
      )
      const sidebar = document.querySelector('.sidebar-default')
      if (sidebar !== null) {
        const sidebarActiveItem = sidebar.querySelectorAll('.active')
        Array.from(sidebarActiveItem, (elem) => {
          if (!elem.closest('ul').classList.contains('iq-main-menu')) {
            const childMenu = elem.closest('ul')
            childMenu.classList.add('show')
            const parentMenu = childMenu
              .closest('li')
              .querySelector('.nav-link')
            parentMenu.classList.add('collapsed')
            parentMenu.setAttribute('aria-expanded', true)
          }
        })
      }
      Array.from(sidebarToggleBtn, (sidebarBtn) => {
        this.sidebarToggle(sidebarBtn)
      })
    },
  },
}
</script>
