import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthRoute from '@modules/auth/router'
import UserRoute from '@modules/user/router'
import HelperRoute from '@modules/helper/router'

import store from '@src/store'
import { lazyLoadView } from '@helpers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => lazyLoadView(import('@layouts/AuthLayout.vue')),
    children: AuthRoute,
  },
  {
    path: '/loading',
    component: () => lazyLoadView(import('@layouts/AppLayout.vue')),
    children: HelperRoute,
  },
  {
    path: '/user',
    component: () => lazyLoadView(import('@layouts/AppLayout.vue')),
    children: UserRoute,
  },
  {
    path: '*',
    component: () => lazyLoadView(import('@layouts/ErrorCodeLayout.vue')),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state['auth']['user'])
      next({
        name: 'login',
      })
  }
  next()
})

export default router
