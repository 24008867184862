export default [
  {
    path: '',
    name: 'loading',
    component: () => import('@components/TestingComponent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'restriction',
    name: 'restriction',
    component: () => import('@components/auth/AuthRestriction.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
