import HelperService from '@modules/helper/service'

export const helper = {
  namespaced: true,
  state: {
    genderData: [
      {
        id: 1,
        name: 'Hombre',
      },
      {
        id: 2,
        name: 'Mujer',
      },
    ],
    typeDocumentData: [
      {
        id: 1,
        name: 'DNI',
      },
      {
        id: 2,
        name: 'RUC',
      },
      {
        id: 3,
        name: 'CE',
      },
    ],
    maritalData: [],
    countryData: [],
    housingTypeData: [],
    workingConditionData: [],
    jobTitleData: [],
    gradeInstructionData: [],
    professionData: [],
    economicActivityData: [],
  },
  actions: {
    async apiStatus({}) {
      return HelperService.apiStatus().then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getMaritalStatus({ commit }) {
      return HelperService.getMaritalStatusAll().then(
        (success) => {
          commit('SET_MARITALSTATUS_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getCountries({ commit }) {
      return HelperService.getCountryAll().then(
        (success) => {
          commit('SET_COUNTRY_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getHousingTypes({ commit }) {
      return HelperService.getHousingTypeAll().then(
        (success) => {
          commit('SET_HOUSINGTYPE_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getWorkingConditions({ commit }) {
      return HelperService.getWorkingConditionAll().then(
        (success) => {
          commit('SET_WORKINGCONDITION_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getJobTitles({ commit }) {
      return HelperService.getJobTitleAll().then(
        (success) => {
          commit('SET_JOBTITLE_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getGradeInstructions({ commit }) {
      return HelperService.getGradeInstructionAll().then(
        (success) => {
          commit('SET_GRADEINSTRUCTIONS_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getProfessions({ commit }) {
      return HelperService.getProfessionAll().then(
        (success) => {
          commit('SET_PROFESSIONS_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async getEconomicActivities({ commit }) {
      return HelperService.getEconomicActivityAll().then(
        (success) => {
          commit('SET_ECONOMICACTIVITIES_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
  },
  mutations: {
    SET_MARITALSTATUS_DATA(state, data) {
      state.maritalData = data
    },
    SET_COUNTRY_DATA(state, data) {
      state.countryData = data
    },
    SET_HOUSINGTYPE_DATA(state, data) {
      state.housingTypeData = data
    },
    SET_WORKINGCONDITION_DATA(state, data) {
      state.workingConditionData = data
    },
    SET_JOBTITLE_DATA(state, data) {
      state.jobTitleData = data
    },
    SET_GRADEINSTRUCTIONS_DATA(state, data) {
      state.gradeInstructionData = data
    },
    SET_PROFESSIONS_DATA(state, data) {
      state.professionData = data
    },
    SET_ECONOMICACTIVITIES_DATA(state, data) {
      state.economicActivityData = data
    },
  },
}
