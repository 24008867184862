<template>
  <div class="loader simple-loader" ref="loading">
    <div class="loader-body"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  async mounted() {
    setTimeout(() => {
      this.$refs.loading.classList.add('animate__animated', 'animate__fadeOut')
      setTimeout(() => {
        this.$refs.loading.classList.add('d-none')
      }, 500)
    }, 500)
  },
}
</script>
