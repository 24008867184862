import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import VueSweetalert2 from 'vue-sweetalert2'
import { Plugin } from 'vue-fragment'
import setupInterceptors from '@helpers/auth/interceptors'

import '@components/app/_globals'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

// Bootstrap
import 'bootstrap'

// Node Modules
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'

// Plugins
import Vuelidate from 'vuelidate'
import Multiselect from 'vue-multiselect'

// VueUse
Vue.use(Vuelidate)
Vue.use(Vuex)
Vue.use(VueSweetalert2)
Vue.use(Plugin)

// VueComponent
Vue.component('multiselect', Multiselect)

setupInterceptors(store)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
