<template>
  <fragment>
    <li class="nav-item" v-if="menu.sub_menu.length === 0">
      <router-link
        :to="{ name: menu.route }"
        class="nav-link"
        aria-current="page"
        :class="currentRouteName === menu.route ? 'active' : ''"
      >
        <i class="icon" v-if="!hide">
          <span :class="menu.icon" />
        </i>
        <span class="item-name" v-html="menu.name" />
      </router-link>
    </li>
    <li class="nav-item" v-else>
      <a
        class="nav-link"
        :href="`#${submenu}${menu.id}`"
        role="button"
        aria-expanded="false"
        :aria-controls="`${submenu}${menu.id}`"
        data-bs-toggle="collapse"
      >
        <i class="icon" v-if="!hide">
          <span :class="menu.icon" />
        </i>
        <span class="item-name" v-html="menu.name" />
        <i class="right-icon">
          <span class="fas fa-chevron-right"></span>
        </i>
      </a>
      <ul
        class="sub-nav collapse"
        :id="`${submenu}${menu.id}`"
        data-bs-parent="#sidebar-menu"
      >
        <template v-for="(item, key) in menu.sub_menu">
          <template v-if="item.sub_menu.length === 0">
            <li class="nav-item" :key="key">
              <router-link :to="{ name: menu.route }" class="nav-link">
                <i class="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                </i>
                <span class="item-name" v-html="item.name"></span>
              </router-link>
            </li>
          </template>
          <template v-else>
            <MenuChild
              :key="key"
              :children="item"
              :submenu="`app-${key}${menu.id}`"
              :hide="true"
            />
          </template>
        </template>
      </ul>
    </li>
  </fragment>
</template>

<script>
import MenuChild from '@components/app/sub_component/MenuChild.vue'

export default {
  name: 'MenuChild',
  components: {
    MenuChild,
  },
  props: {
    children: Object,
    hide: Boolean,
    submenu: {
      type: String,
      default: 'submenu-app',
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  data: function () {
    return {
      menu: this.children,
    }
  },
}
</script>
