<template>
  <fragment>
    <div class="sidebar-body pt-0 data-scrollbar">
      <div class="sidebar-list">
        <ul class="navbar-nav iq-main-menu" id="sidebar-menu">
          <template v-for="(item, key) in menuLogic">
            <template v-if="item.parent === 0">
              <MenuChild
                :key="key"
                :children="item"
                :hide="false"
                :class="disabled ? 'disabled' : ''"
                :disabled="disabled"
              />
            </template>
          </template>
        </ul>
      </div>
    </div>
    <div class="sidebar-footer"></div>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MenuChild from '@components/app/sub_component/MenuChild.vue'

export default {
  components: {
    MenuChild,
  },
  inheritAttrs: false,
  data: function () {
    return {
      dataRoleMenu: [],
      disabled: false,
      menuLogic: [],
    }
  },
  computed: {
    ...mapState({
      menu: (state) => state.menu.data,
      user: (state) => state.user.data,
    }),
  },
  methods: {
    ...mapMutations({
      setMenuRole: 'menu/SET_MENU_ROLE',
    }),
    async initMenuParent() {
      const dataUser = this.user
      let arrayMenu = dataUser?.role?.menus
      let arrayMenuValid = []
      if (arrayMenu)
        arrayMenu.map((item) => {
          if (!arrayMenuValid.includes(item.menu_id))
            arrayMenuValid.push(item.menu_id)
        })
      this.dataRoleMenu = arrayMenuValid
      this.menuRoles()
      this.resizePlugins()
      window.addEventListener('resize', this.resizePlugins)
    },
    menuRoles() {
      if (this.menu.length) {
        let roleMenu = this.dataRoleMenu
        const menu = this.menu
        let menuAll = []
        menu.map((item) => {
          if (roleMenu.includes(item.id)) {
            let children = item
            item.sub_menu = this.childrenMenu(menu, item)
            menuAll = [...menuAll, children]
          }
        })
        this.menuLogic = menuAll
        this.setMenuRole(menuAll)
      }
    },
    childrenMenu(menu, line) {
      let roleMenu = this.dataRoleMenu
      let children = []
      menu.map((item) => {
        if (roleMenu.includes(item.id)) {
          if (line.id === item.parent) {
            item.sub_menu = this.childrenMenu(menu, item)
            children = [...children, item]
          }
        }
      })

      return children
    },
    async logout() {
      this.$parent.logout()
    },
    resizePlugins() {
      const tabs = document.querySelectorAll('.nav')
      const sidebarResponsive = document.querySelector('.sidebar-default')
      if (window.innerWidth < 1025) {
        Array.from(tabs, (elem) => {
          if (
            !elem.classList.contains('flex-column') &&
            elem.classList.contains('nav-tabs') &&
            elem.classList.contains('nav-pills')
          ) {
            elem.classList.add('flex-column', 'on-resize')
          }
        })
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('sidebar-mini', 'on-resize')
          }
        }
      } else {
        Array.from(tabs, (elem) => {
          if (elem.classList.contains('on-resize')) {
            elem.classList.remove('flex-column', 'on-resize')
          }
        })
        if (sidebarResponsive !== null) {
          if (
            sidebarResponsive.classList.contains('sidebar-mini') &&
            sidebarResponsive.classList.contains('on-resize')
          ) {
            sidebarResponsive.classList.remove('sidebar-mini', 'on-resize')
          }
        }
      }
    },
  },
}
</script>

<style></style>
