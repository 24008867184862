<template>
  <offline-layout v-if="offlineConfirmed" />
  <loading-layout v-else />
</template>

<script>
import { mapActions } from 'vuex'
import OfflineLayout from '@layouts/OfflineLayout.vue'
import LoadingLayout from '@layouts/LoadingLayout.vue'

export default {
  name: 'timeout',
  components: {
    OfflineLayout,
    LoadingLayout,
  },
  data() {
    return {
      offlineConfirmed: false,
    }
  },
  created() {
    this.apiStatus().then(
      (response) => {
        window.location.reload()
      },
      (error) => {
        this.offlineConfirmed = true
      }
    )
  },
  methods: {
    ...mapActions({
      apiStatus: 'helper/apiStatus',
    }),
  },
}
</script>
