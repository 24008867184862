export default [
  {
    path: '',
    name: 'login',
    component: () => import('@views/auth/AuthLogin.vue'),
  },
  {
    path: 'forgot_password',
    name: 'forgot_password',
    component: () => import('@views/auth/ForgotPassword.vue'),
  },
]
