<template>
  <section class="vh-100 d-flex align-items-center justify-content-center">
    <div class="container">
      <div class="row">
        <div
          class="
            col-12
            text-center
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <div>
            <a href="javascript:void(0)">
              <img
                class="img-fluid"
                src="@assets/images/illustrations/500.png"
                alt="404 not found"
              />
            </a>
            <h1 class="mt-5"
              >Pagina
              <span class="font-weight-bolder text-primary"
                >sin conexión</span
              ></h1
            >
            <p class="lead my-4">
              Se agotó el tiempo de espera de la página mientras se cargaba.<br />
              ¿Estás seguro de que todavía estás conectado a Internet?.<br />
              Actualiza la pagina web para intentar de nuevo.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OfflineLayout',
  components: {},
}
</script>

<style></style>
