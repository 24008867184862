import MenuService from '@modules/menu/services'

export const menu = {
  namespaced: true,
  state: {
    data: [],
    role: [],
  },
  actions: {
    async loadMenu({ commit }) {
      return MenuService.getAll().then(
        (success) => {
          commit('SET_MENU', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
  },
  mutations: {
    SET_MENU(state, menu) {
      state.data = menu
    },
    SET_MENU_ROLE(state, menu) {
      state.role = menu
    },
  },
  getters: {
    menuRole: (state, getters) => {
      return state.role ? state.role : {}
    },
  },
}
