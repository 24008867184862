import api from '@helpers/api'

class MenuService {
  async getAll() {
    const response = await api.get('/menu')
    return response.data
  }
}

export default new MenuService()
