<template>
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :id="id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="id"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="size ? `modal-${size}` : ''"
      role="document"
    >
      <div class="card modal-content">
        <div class="card-body">
          <h5
            class="card-title modal-header border-bottom border-5 border-light"
            v-if="title"
          >
            <slot name="header"> </slot>
          </h5>
          <div class="modal-body">
            <slot name="body"> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
    },
    size: {
      type: String,
    },
    title: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
}
</script>
