import { lazyLoadView } from '@helpers'

export default [
  {
    path: 'all',
    name: 'user-all',
    component: () => lazyLoadView(import('@views/user/ListRecords.vue')),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: 'profile',
    name: 'user-profile',
    component: () => lazyLoadView(import('@views/user/MyProfile.vue')),
    meta: {
      requiresAuth: true,
    },
  },
]
