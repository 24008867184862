const swalHelper = (
  toast = true,
  icon = 'info',
  position = 'bottom-end',
  message = '',
  timer = 4000
) => {
  return {
    toast: toast,
    icon: icon,
    position: position,
    html: message,
    showConfirmButton: false,
    showCloseButton: false,
    showCancelButton: false,
    showDenyButton: false,
    timer: timer,
    timerProgressBar: true,
    allowEscapeKey: false,
    allowEnterKey: false,
  }
}

const successRequest = (data, notify) => {
  let message = data.message
  return notify(swalHelper(undefined, 'success', undefined, message))
}

const successNotify = (data, notify) => {
  let message = data.message
  return notify(swalHelper(false, 'success', 'center', message, 1500))
}

const errorRequest = (e, notify) => {
  let response = e?.response?.data
  let message = response?.message

  if (response?.detail?.length) {
    let messages = ''
    let detail = response.detail
    detail.map((item) => (messages += `${item.loc[1]} ${item.msg} <br>`))
    return notify(swalHelper(undefined, 'error', undefined, messages))
  }

  return notify(swalHelper(undefined, 'error', undefined, message))
}

const fileFormat = (file) => {
  switch (file) {
    case 'application/json':
      return {
        icon: 'fas fa-file-alt text-dark',
        type: 'JSON',
      }
    case 'application/vnd.ms-excel':
      return {
        icon: 'fas fa-file-excel text-success',
        type: 'CSV',
      }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return {
        icon: 'fas fa-file-excel text-success',
        type: 'EXCEL',
      }
    case 'application/pdf':
      return {
        icon: 'fas fa-file-pdf text-danger',
        type: 'PDF',
      }
    default:
      return {
        icon: 'fas fa-file',
        type: 'Otro',
      }
  }
}

const fileBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const chronometerSeconds = (s) => {
  function addZ(n) {
    return (n < 10 ? '0' : '') + n
  }

  let ms = s % 1000
  s = (s - ms) / 1000
  let secs = s % 60
  s = (s - secs) / 60
  let mins = s % 60
  let hrs = (s - mins) / 60

  return `${addZ(hrs)}:${addZ(mins)}:${addZ(secs)}`
}

const lazyLoadView = (AsyncView) => {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@views/loading.vue').default,
    delay: 400,
    error: require('@views/timeout.vue').default,
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

export {
  successRequest,
  successNotify,
  errorRequest,
  chronometerSeconds,
  fileBytes,
  fileFormat,
  lazyLoadView,
}
