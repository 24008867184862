import api from '@helpers/api'
import TokenService from '@helpers/auth/token'

class AuthService {
  async login({ username, password }) {
    const response = await api.post('/auth/login', {
      username,
      password,
    })
    if (response.data.access_token) TokenService.setUser(response.data)
    return response.data
  }

  async resetPassword({ username }) {
    const response = await api.post('/auth/reset_password', {
      username,
    })
    return response.data
  }

  logout() {
    TokenService.removeUser()
  }
}

export default new AuthService()
