import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@modules/auth/store'
import { menu } from '@modules/menu/store'
import { user } from '@modules/user/store'
import { helper } from '@modules/helper/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumb: '',
  },
  actions: {
    setBreadCrumb({ commit }, breadcrumb) {
      commit('SET_BREADCRUMB', breadcrumb)
    },
  },
  mutations: {
    SET_BREADCRUMB(state, breadcrumb) {
      state.breadcrumb = breadcrumb
    },
  },
  modules: {
    auth,
    menu,
    user,
    helper,
  },
  strict: process.env.NODE_ENV !== 'production',
})
