import AuthService from '@modules/auth/service'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit('LOGIN_SUCCESS', user)
          return Promise.resolve(user)
        },
        (error) => {
          commit('LOGIN_FAILURE')
          return Promise.reject(error)
        }
      )
    },
    resetPassword({}, user) {
      return AuthService.resetPassword(user).then(
        (user) => {
          return Promise.resolve(user)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    logout({ commit }) {
      AuthService.logout()
      commit('LOGOUT')
    },
    refreshToken({ commit }, access_token) {
      commit('REFRESH_TOKEN', access_token)
    },
  },
  mutations: {
    LOGIN_SUCCESS(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    LOGIN_FAILURE(state) {
      state.status.loggedIn = false
      state.user = null
    },
    LOGOUT(state) {
      state.status.loggedIn = false
      state.user = null
    },
    REFRESH_TOKEN(state, access_token) {
      state.status.loggedIn = true
      state.user = { ...state.user, access_token: access_token }
    },
  },
}
