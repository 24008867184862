import UserService from '@modules/user/service'

export const user = {
  namespaced: true,
  state: {
    data: {},
    dataTable: {},
    dataUpdate: {},
  },
  actions: {
    async getData({ commit }, { id }) {
      return UserService.getID({ id }).then(
        (success) => {
          commit('SET_DATA', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async loadDataTable({ commit }, { page, q }) {
      return UserService.getAll({ page, q }).then(
        (success) => {
          commit('SET_DATATABLE', success.data)
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async selectOptions({}) {
      return UserService.getSelectOptions().then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async create({}, data) {
      return UserService.create(data).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async update({}, data) {
      return UserService.update(data).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async updateStatus({}, data) {
      return UserService.updateStatus(data).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async delete({}, data) {
      return UserService.delete(data).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async report({}, filters) {
      return UserService.report(filters).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async import({}, data) {
      return UserService.import(data).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    async sendEmailWelcome({}, { id }) {
      return UserService.sendEmailWelcome({ id }).then(
        (success) => {
          return Promise.resolve(success)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    setDataTable({ commit }, data) {
      commit('SET_DATATABLE', data)
    },
    setDataUpdate({ commit }, data) {
      let dataUser = Object.assign({}, data)
      commit('SET_DATA_UPDATE', dataUser)
    },
    unSetDataUpdate({ commit }, data) {
      commit('UNSET_DATA_UPDATE', data)
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    SET_DATATABLE(state, data) {
      state.dataTable = data
    },
    SET_DATA_UPDATE(state, data) {
      state.dataUpdate = data
    },
    UNSET_DATA_UPDATE(state) {
      state.dataUpdate = {}
    },
  },
  getters: {
    userData: (state, getters) => {
      return state.data ? state.data : {}
    },
    userDataUpdated: (state, getters) => {
      return state.dataUpdate ? state.dataUpdate : {}
    },
    userMenu: (state, getters) => {
      let menus = state.data?.role?.menus
      const menuRole = menus?.map((item) => item.menu.route)
      return menuRole
    },
  },
}
