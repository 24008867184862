import axiosInstance from '@helpers/api'
import TokenService from '@helpers/auth/token'

const setupInterceptors = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken()
      if (token) config.headers['Authorization'] = `Bearer ${token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (err.response.status === 422) {
        originalConfig._retry = true

        TokenService.updateLocalAccessToken(TokenService.getLocalRefreshToken())

        setTimeout(async () => {
          try {
            const response = await axiosInstance.post('/auth/token/refresh')
            const { access_token } = response.data
            store.dispatch('auth/refreshToken', access_token)
            TokenService.updateLocalAccessToken(access_token)
            return axiosInstance(originalConfig)
          } catch (e) {
            await store.dispatch('auth/logout')
            window.location.reload()
            return Promise.reject(error)
          }
        }, 500)
      }

      return Promise.reject(err)
    }
  )
}

export default setupInterceptors
